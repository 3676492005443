import React, { useEffect, useState } from 'react';
import { ProjectCardDataInterface } from '../../interfaces/ProjectCardDataInterface';
import { ProjectCard } from './'

interface ProjectSliderInterface {
  projectCards: ProjectCardDataInterface[]
}

export const ProjectSlider: React.FC<ProjectSliderInterface> = ({ projectCards }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalCards = projectCards.length;

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalCards);
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalCards) % totalCards);
  };

  // Auto avance solo en móviles
/*   useEffect(() => {
    if (window.innerWidth < 768) {
      const interval = setInterval(() => {
        goToNext();
      }, 5000); // Cambia cada 5 segundos
      return () => clearInterval(interval);
    }
  }, []); */

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setCurrentIndex(0);
      }
    };
  
    if (window.innerWidth >= 1024) {
      window.addEventListener('resize', handleResize);
    }
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);  

  return (
    <div className="bg-projects-slider relative flex items-center justify-center  w-full ">

      <button
        onClick={goToPrevious}
        className="lg:hidden absolute left-4 text-white bg-gray-800 p-2 rounded-full hover:bg-gray-600 z-50"
      >
        ◀
      </button>

      <div className="overflow-hidden    pb-4">
        <div
          className="flex transition-transform  duration-500 ease-in-out"
          style={{
            transform: `translateX(-${currentIndex * (100 / (window.innerWidth >= 1024 ? 3 : 1))}%)`,
          }}
        >
          {projectCards.map((projectCard) => (
            <ProjectCard key={projectCard.id} projectCard={projectCard} />
          ))}
        </div>
      </div>

      <button
        onClick={goToNext}
        className="lg:hidden absolute right-4 text-white bg-gray-800 p-2 rounded-full hover:bg-gray-600 z-50"
      >
        ▶
      </button>

    </div>
  );
};
