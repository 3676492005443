import axios from "axios";
import { urls } from "../helpers/urls";

const getToken = () => localStorage.getItem("token") ?? '';

export const serviceLogin = async (data) => {
  try {
    const user = await axios.post(`${urls.NEW_API_URL}auth/signIn`, data);
    return user;
  } catch (error) {
    throw error.response.data;
  }
}

export const serviceRegister = async (data) => {
  const body = {
    countryPhoneCode: data.countryPhoneCode,
    email: data.email,
    firstName: data.first_name,
    lastName: data.last_name,
    leadOrigin: data.lead_origin,
    pageOrigin: data.pageOrigin,
    password: data.password,
    phone: data.phone,
    referralCode: data.referral_code,
    termsAccepted: data.terms,
    utmSource: data.utmSource,
    utmMedium: data.utmMedium,
    utmCampaign: data.utmCampaign,
    utmTerm: data.utmTerm,
    utmContent: data.utmContent
  }
  try {
    const user = await axios.post(`${urls.NEW_API_URL}auth/signUp`, body);
    return user;
  } catch (error) {
    throw error.response.data;
  }
}




export const generateRegisterInCRM = async (data) => {
  try {
    const response = await axios.post(`https://api-crm-4.onrender.com/register`,
    {
      countryPhoneCode: data.countryPhoneCode,
      email: data.email,
      firstName: data.first_name,
      lastName: data.last_name,
      leadOrigin: data.lead_origin,
      phone: data.phone
    });
    
    return response;
  } catch (error) {
    throw error?.response.data;
  }

}

export const resetPassword = async (email) => {
  try {
    const user = await axios.post(`${urls.NEW_API_URL}auth/recoveryPassword`, {email});
    return user;
  } catch (error) {
    throw error.response.data;
  }
}

export const changePassword = async (data) => {
  try {
    const user = await axios.put(`${urls.NEW_API_URL}auth/resetPassword`, data, {
      headers: {
        Authorization: `Bearer ${data.token}`,
        'Content-Type': 'application/json',
      },
    });
    return user;
  }
  catch (error) {
    throw error.response.data;
  }
}

export const confirmCode = async (email, otp) => {
  try {
    const body = {
      email,
      token: otp
    }
    const response = await axios.post(`${urls.NEW_API_URL}auth/verifyEmail`, body, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    throw error.response.data;
  }
}