import React from 'react';
import MainHeaderWithCTA from './sections/MainHeaderWithCTA';
import AmbassadorWhoWeAre from './sections/AmbassadorWhoWeAre';
import amabassador_blue_ckeck from "../../assets/img/ambassadors/ambassador-check-blue.png";

import AmbassadorBenefits from './sections/AmbassadorBenefits';
import AmbassadorHowItsWorks from './sections/AmbassadorHowItsWorks';
import AmbassadorStepsToRefer from './sections/AmbassadorStepsToRefer';
import useTitle, { useDescription, useKeywords } from '../../hooks/useSEO';

export default function Ambassadors() {

  useTitle("Programa de Embajadores en Inversiones | Gana dinero como embajador | LOKL");
  useDescription("Conviértete en embajador de LOKL y gana dinero desde casa. Descubre los beneficios de ser embajador de inversiones inmobiliarias y cómo ganar dinero con referidos inmobiliarios.");
  useKeywords("programa de embajadores en inversiones, gana dinero como embajador, gana dinero desde casa, embajadores de inversiones inmobiliarias, beneficios de ser embajador de LOKL, programa de referidos en bienes raíces, cómo ganar dinero con referidos inmobiliarios, recompensas para embajadores de LOKL, invierte y gana como embajador, marketing de afiliados en bienes raíces, programa de referidos con alto ROI");
 

  return (
    <main className="space-y-8 md:space-y-14">
      <header>
      <MainHeaderWithCTA />
      </header>
      <article>
        <section>
        <AmbassadorWhoWeAre />
        </section>
      

      <div className="relative flex justify-center">
        <img
          src={amabassador_blue_ckeck}
          alt="Check azul"
          className="w-24 h-24 md:w-52 md:h-52 -translate-y-20 sm:-translate-y-32 md:-translate-y-26 lg:-translate-y-40"
        />
      </div>
      <section>
      <AmbassadorBenefits />
      </section>
      <section>
      <AmbassadorHowItsWorks />
      </section>
      <section>
      <AmbassadorStepsToRefer />
      </section>
      
    
      </article>
     
    </main>
  );
}
